import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import {
  EscrowDevice,
  EscrowDevices,
  Secrets,
  Secret,
  SecretsFilters,
} from '@edgeiq/edgeiq-api-js';
import { LwM2MSecretData } from '../../../models/lwm2m_secret_data';
import clsx from 'clsx';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import {
  setStateEscrowDevices,
  setSelectedEscrowDeviceSecretData,
} from '../../../redux/reducers/devices.reducer';
import {
  errorHighlight,
  optionsPaginationsFilter,
} from '../../../app/constants';
import TextInput from '../../../components/TextInput';
import useStyles from '../styles';
import EscrowDeviceMetadata from './EscrowDeviceMetadata';
import { MetadataListProps } from '../activeDevices/ActiveDevicesMoreFilters';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';

interface IssueTransferDrawerProps {
  open: boolean;
  handleCloseDrawer: () => void;
}

const EscrowDeviceDrawer: React.FC<IssueTransferDrawerProps> = ({
  open,
  handleCloseDrawer,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [metadataList, setMetadataList] = useState<MetadataListProps[]>([]);

  const [loading, setLoading] = useState(false);

  const escrowDevices = useAppSelector(
    (state: RootState) => state.devices.escrowDevices,
  );
  const stateEscrowDevice = useAppSelector(
    (state: RootState) => state.devices.escrowDevice,
  );
  const statePSKSecretData = useAppSelector(
    (state: RootState) => state.devices.selectedEscrowDeviceSecretData,
  );
  const [escrowDevice, setEscrowDevice] = useState<EscrowDevice>(
    stateEscrowDevice as EscrowDevice,
  );
  const [pskSecretData, setPskSecretData] = useState<LwM2MSecretData>(
    statePSKSecretData as LwM2MSecretData,
  );
  const [editingPSK, setEditingPSK] = useState(false);

  useEffect(() => {
    if (escrowDevice?.metadata) {
      const auxArray = [];
      if (escrowDevice?.metadata) {
        for (const [key, value] of Object.entries(escrowDevice?.metadata)) {
          auxArray.push({ key, value });
        }
      }
      setMetadataList(auxArray);
    }
  }, [escrowDevice?.metadata]);

  useEffect(() => {
    setEscrowDevice(stateEscrowDevice as EscrowDevice);
    getCurrentSecrets(stateEscrowDevice as EscrowDevice);
  }, [stateEscrowDevice]);

  useEffect(() => {
    setPskSecretData(statePSKSecretData as LwM2MSecretData);
  }, [statePSKSecretData]);

  const updateEscrowDevice = (): void => {
    setLoading(true);
    const metadataObj = metadataList.length
      ? metadataList.reduce(
          (prevMetadata, nextMetadata) => ({
            ...prevMetadata,
            [nextMetadata.key as string]: nextMetadata.value,
          }),
          { [metadataList[0].key as string]: metadataList[0].value },
        )
      : {};
    EscrowDevices.update({ ...escrowDevice, metadata: metadataObj })
      .then((response) => {
        setAlert({
          highlight: 'Escrow device updated',
          message: 'Escrow device successfully deleted.',
          type: 'success',
        });
        // TODO: update the escrow device in the list in redux.
        const newDevices = escrowDevices.map((device) => {
          if (device._id === escrowDevice._id) {
            return response;
          }
          return device;
        });
        dispatch(setStateEscrowDevices(newDevices));
        handleCloseDrawer();
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
        setEditingPSK(false);
      });
  };
  const getCurrentSecrets = (deviceWithSecrets?: EscrowDevice): void => {
    if (deviceWithSecrets) {
      setLoading(true);
      const filters: SecretsFilters = {
        _id: {
          operator: 'in',
          value: [
            deviceWithSecrets.lwm2m_bootstrap_psk_secret_id as string,
            deviceWithSecrets.lwm2m_server_psk_secret_id as string,
          ],
        },
      };
      Secrets.list(filters, optionsPaginationsFilter)
        .then((result) => {
          const secretData: LwM2MSecretData = {
            bootstrap_psk_hash: '',
            server_psk_hash: '',
          };
          result.secrets.forEach((secret: Secret) => {
            if (
              secret._id === deviceWithSecrets.lwm2m_bootstrap_psk_secret_id
            ) {
              secretData.bootstrap_psk_hash = secret.hash as string;
            } else if (
              secret._id === deviceWithSecrets.lwm2m_server_psk_secret_id
            ) {
              secretData.server_psk_hash = secret.hash as string;
            }
          });
          dispatch(setSelectedEscrowDeviceSecretData(secretData));
        })
        .catch((error) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: error.message,
              type: 'error',
            }),
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const handleInputChange = (prop: string, value: string | number): void => {
    setEscrowDevice({
      ...escrowDevice,
      [prop]: value,
    });
  };

  const disabled = !escrowDevice?.unique_id || !escrowDevice?.token;

  return (
    <RightDrawer
      open={open}
      actionLabel="Update"
      title="Edit Escrow Device"
      disableAction={disabled}
      actionLoading={loading}
      actionCallback={updateEscrowDevice}
      onCloseDrawer={handleCloseDrawer}
      content={
        <Grid container flexDirection="column">
          <Grid item xs={12} className="mb-6">
            <TextInput
              label="Unique ID"
              prop="unique_id"
              required={true}
              value={escrowDevice?.unique_id}
              onInputChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} className="mb-6">
            <TextInput
              label="Token"
              prop="token"
              required={true}
              value={escrowDevice?.token}
              onInputChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} className="mb-6">
            <TextInput
              label="Serial Number"
              prop="serial"
              value={escrowDevice?.serial}
              onInputChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={12} className="mb-6">
            <TextInput
              label="Part Number"
              prop="part_number"
              value={escrowDevice?.part_number}
              onInputChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} className="mb-6">
            <Typography
              component="div"
              className={clsx('mb-4', classes.subtitle)}
            >
              {'Bootstrap PSK Identity'}
            </Typography>
            <TextareaAutosize
              disabled={true}
              aria-label="empty"
              placeholder="Empty"
              value={escrowDevice?.lwm2m_bootstrap_psk_identity}
            />
          </Grid>
          <Grid item xs={12} className="mb-6">
            <Typography
              component="div"
              className={clsx('mb-4', classes.subtitle)}
            >
              {'Bootstrap PSK Hash (SHA 256)'}
            </Typography>
            <TextareaAutosize
              disabled={true}
              aria-label="empty"
              placeholder="Empty"
              value={pskSecretData?.bootstrap_psk_hash}
            />
          </Grid>
          {!editingPSK && (
            <>
              <Grid item xs={12} className="mb-6">
                <Typography
                  component="div"
                  className={clsx('mb-4', classes.subtitle)}
                >
                  {'Server PSK Identity'}
                </Typography>
                <TextareaAutosize
                  disabled={true}
                  aria-label="empty"
                  placeholder="Empty"
                  value={escrowDevice?.lwm2m_server_psk_identity}
                />
              </Grid>
              <Grid item xs={12} className="mb-6">
                <Typography
                  component="div"
                  className={clsx('mb-4', classes.subtitle)}
                >
                  {'Server PSK Hash (SHA 256)'}
                </Typography>
                <TextareaAutosize
                  disabled={true}
                  aria-label="empty"
                  placeholder="Empty"
                  value={pskSecretData?.server_psk_hash}
                />
              </Grid>
              <Typography
                variant="button"
                onClick={(): void => setEditingPSK(true)}
              >
                Edit Server PSK
              </Typography>
            </>
          )}
          {editingPSK && (
            <>
              <Grid item xs={12} className="mb-6">
                <TextInput
                  label="Server PSK"
                  prop="lwm2m_server_psk"
                  value={escrowDevice?.lwm2m_server_psk}
                  onInputChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} className="mb-6">
                <TextInput
                  label="Server PSK Identity"
                  prop="lwm2m_server_psk_identity"
                  value={escrowDevice?.lwm2m_server_psk_identity}
                  onInputChange={handleInputChange}
                />
              </Grid>
              <Typography
                variant="button"
                onClick={(): void => setEditingPSK(false)}
              >
                Cancel Edit Server PSK
              </Typography>
            </>
          )}
          <Grid item xs={12} className="mb-6">
            <Typography
              component="div"
              className={clsx('mb-4', classes.subtitle)}
            >
              {'Metadata'}
            </Typography>
            <EscrowDeviceMetadata
              metadata={metadataList || []}
              setMetadataList={setMetadataList}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default EscrowDeviceDrawer;
