import React, { useState, useEffect } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { ConnectionConfig } from '@edgeiq/edgeiq-api-js';

import SelectInput from '../../../../components/SelectInput';
import TextInput from '../../../../components/TextInput';
import SwitchButton from '../../../../components/SwitchButton';
import {
  deviceConfigConnectionCellAuth,
  deviceConfigConnectionTimeLabel,
} from '../../../../app/constants';
import {
  EMPTY_CONFIG_CONNECTION_WAN,
  EMPTY_CONFIG_CONNECTION_WANLAN,
} from '../../../../constants/configConnections';

interface DeviceConfigConnectionFormProps {
  deviceConnection?: ConnectionConfig;
  configIndex: number;
  onInputChange: (
    prop: string,
    value: string | number,
    field: string,
    index: number,
  ) => void;
}

const DeviceConfigConnectionCellularForm: React.FC<
  DeviceConfigConnectionFormProps
> = ({ configIndex, deviceConnection, onInputChange }) => {
  const [configConnection, setConfigConnection] = useState<ConnectionConfig>(
    deviceConnection ||
      EMPTY_CONFIG_CONNECTION_WAN ||
      EMPTY_CONFIG_CONNECTION_WANLAN,
  );

  const [connectionTimeType, setConnectionTimeType] = useState<string>('Days');
  const [dhcpLeaseValue, setDhcpLeaseValue] = useState(1);

  const handerLocalConfigConnection = (
    prop: string,
    value: string | number | boolean,
  ): void => {
    setConfigConnection((currentConfig) => {
      return (currentConfig = {
        ...currentConfig,
        [prop]: value,
      });
    });
  };

  useEffect(() => {
    const value = convertToSeconds() as number;
    onInputChange('dhcp_lease', value as number, '', configIndex);
  }, [dhcpLeaseValue]);

  const convertToSeconds = (time?: string): number | undefined => {
    const currentTimeType = time || connectionTimeType;
    switch (currentTimeType) {
      case deviceConfigConnectionTimeLabel.Seconds:
        return dhcpLeaseValue;
      case deviceConfigConnectionTimeLabel.Minutes:
        return dhcpLeaseValue * 60;
      case deviceConfigConnectionTimeLabel.Hours:
        return dhcpLeaseValue * 3600;
      case deviceConfigConnectionTimeLabel.Days:
        return dhcpLeaseValue * 86400;
    }
  };

  const handleDynamicChange =
    (prop: string, index: number) =>
    (field: string, value: string | number): void => {
      if (prop === 'cell_reconn_delay') {
        setDhcpLeaseValue(parseInt(value as string));
        return;
      }
      handerLocalConfigConnection(prop, value as string);
      onInputChange(prop, value, field, index);
    };

  const handleChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    if (prop === 'connectionTimeType') {
      prop = 'cell_reconn_delay';
      setConnectionTimeType(value as string);
      value = convertToSeconds(value as string) as number;
    }
    handerLocalConfigConnection(prop, value as boolean);
    onInputChange(prop, value as number, '', configIndex);
  };

  return (
    <>
      <Grid container direction="row" className="mt-2" spacing={2}>
        <Grid item xs={12}>
          <SwitchButton
            label="Enabled"
            value={configConnection?.cell_enable === 1}
            prop="cell_enable"
            onSwitchChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label="Second"
            prop="cell_auth"
            value=""
            onSelectChange={handleDynamicChange('cell_auth', configIndex)}
            options={Object.keys(deviceConfigConnectionCellAuth).map(
              (key, index) => (
                <MenuItem className="m-4 p-2" key={index} dense value={key}>
                  {deviceConfigConnectionCellAuth[key]}
                </MenuItem>
              ),
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            prop="cell_pass"
            label="Password"
            placeholder=""
            type="text"
            value={configConnection?.cell_pass}
            onInputChange={handleDynamicChange('cell_pass', configIndex)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            prop="cell_reconn_delay"
            label="Reconnect Delay"
            placeholder=""
            type="number"
            value={dhcpLeaseValue}
            onInputChange={handleDynamicChange(
              'cell_reconn_delay',
              configIndex,
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label={'Time'}
            prop={'connectionTimeType'}
            value={connectionTimeType}
            onSelectChange={handleChange}
            options={Object.keys(deviceConfigConnectionTimeLabel).map(
              (key, index) => (
                <MenuItem className="m-4 p-2" key={index} dense value={key}>
                  {deviceConfigConnectionTimeLabel[key]}
                </MenuItem>
              ),
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            prop="cell_user"
            label="User Name"
            placeholder=""
            type="text"
            value={configConnection?.cell_user}
            onInputChange={handleDynamicChange('cell_user', configIndex)}
          />
        </Grid>

        <Grid item xs={12}>
          <SwitchButton
            label="Automatic APN"
            value={configConnection?.cell_auto_apn === 1}
            prop="cell_auto_apn"
            onSwitchChange={handleChange}
          />
        </Grid>
        {!configConnection.cell_auto_apn && (
          <Grid item xs={12}>
            <TextInput
              prop="cell_apn"
              label="Access Point Name (APN)"
              placeholder=""
              type="text"
              value=""
              onInputChange={handleDynamicChange('cell_apn', configIndex)}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <TextInput
            prop="cell_reconn_retries"
            label="Reconnection Retries"
            placeholder=""
            type="number"
            value=""
            onInputChange={handleDynamicChange(
              'cell_reconn_retries',
              configIndex,
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            prop="cell_mtu"
            label="Cellular Max Transmission Unit"
            placeholder=""
            type="number"
            value=""
            onInputChange={handleDynamicChange('cell_mtu', configIndex)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DeviceConfigConnectionCellularForm;
