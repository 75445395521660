import React from 'react';
import {
  AwsThingGroup,
  Company,
  Device,
  DeviceType,
  EIQFile,
  GatewayCommand,
} from '@edgeiq/edgeiq-api-js';
import { capitalize } from 'lodash';

import { ActionDispatcher } from '../redux/hooks';
import { setAlert } from '../redux/reducers/alert.reducer';
import {
  LWM2M_TYPE,
  LWM2M_STATUS_EXECUTE,
  errorHighlight,
} from '../app/constants';
import { getToken } from './storage';

export const getCompanyDescription = (
  accountID: string,
  accounts: Company[],
): string => {
  // Using this in here is creating a rendering hook error where it is used. It is not recomendable to have hooks in the rendering part, and that's
  // where we call this function, in the rendering we shouldn't have hooks otherwise it will rerun more hooks than initiated and that's a breaking error.
  // const { userCompanies } = useAppSelector((state: RootState) => state.user);
  const company = accounts?.find(
    (companyItem) => companyItem._id === accountID,
  );
  return company?.name || accountID;
};

export const getLwm2mDeviceStatus = (
  deviceType: string,
  command: GatewayCommand,
): string => {
  if (deviceType !== LWM2M_TYPE) {
    return '';
  }

  if (command?.device_unique_id) {
    if (command.statuses[command.device_unique_id].status === 'downloaded') {
      return LWM2M_STATUS_EXECUTE;
    }
  }
  return '';
};

export const dispatchError =
  (dispatch: ActionDispatcher) =>
  (errorMessage: string, highlight = errorHighlight): void => {
    dispatch(
      setAlert({
        highlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

export const downloadEIQFile = async (file: EIQFile): Promise<void> => {
  const token = getToken();
  const myHeaders = new Headers();
  myHeaders.append('Authorization', token as string);
  try {
    const res = await fetch(
      file.link
        ? file.link
        : `${process.env.REACT_APP_API_URL}files/${file.id}/download`,
      {
        headers: {
          Authorization: `${token}`,
        },
        method: 'GET',
      },
    );

    const blob = await res.blob();
    downloadBlobFile(blob, `${file.name}`);
  } catch (error) {
    console.error(error);
  }
};

export const downloadBlobFile = (file: File | Blob, name: string): void => {
  try {
    const newBlob = new Blob([file]);
    const blobUrl = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    // clean up Url
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error(error);
  }
};

export const getPageHash = (
  hash: string,
  defaultTab: string,
  tablsLabels: Record<string, string>,
): string => {
  if (!hash) {
    return defaultTab;
  }
  const realHash = hash.split('#');
  if (realHash[1]) {
    const keys = Object.keys(tablsLabels);
    const parsedHash = realHash[1].replaceAll('-', '_');
    if (keys.includes(parsedHash)) {
      return parsedHash;
    }
  }
  return defaultTab;
};

export const formatReadableActionName = (string: string): string => {
  if (string.indexOf('_') !== -1) {
    let formatted = '';
    string.split('_').forEach((section) => {
      formatted += `${section.charAt(0).toUpperCase() + section.slice(1)} `;
    });
    string = formatted;
  } else {
    string = string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};

export const checkHasLwm2mDevices = (
  selectedDevices: Device[],
  devicesTypes: DeviceType[],
): {
  hasLwm2mDevices: boolean;
  hasOtherDevices: boolean;
} => {
  let hasLwm2mDevices = false;
  let hasOtherDevices = false;
  (selectedDevices || []).forEach((device) => {
    const deviceType = devicesTypes.find(
      (item) => item._id === device.device_type_id,
    );
    if (deviceType && deviceType.type !== LWM2M_TYPE && !hasOtherDevices) {
      hasOtherDevices = true;
    }
    if (deviceType?.type === LWM2M_TYPE && !hasLwm2mDevices) {
      hasLwm2mDevices = true;
    }
  });
  return { hasLwm2mDevices, hasOtherDevices };
};

export const checkMixedTypes = (
  selectedDevices: Device[],
  devicesTypes: DeviceType[],
): boolean => {
  const { hasLwm2mDevices, hasOtherDevices } = checkHasLwm2mDevices(
    selectedDevices,
    devicesTypes,
  );
  return hasLwm2mDevices && hasOtherDevices;
};

export const convertCommandStatus = (status: string): string => {
  switch (status) {
    case 'fail':
      return 'error';

    case 'error':
      return 'error';

    case 'success':
      return 'success';

    case 'downloaded':
      return 'initiated';

    case 'retry':
    case 'retry_failed':
      return 'retry';

    default:
      return 'initiated';
  }
};

export const updateThingGroupOnChange = (
  thingGroups: AwsThingGroup[] | undefined,
  value: AwsThingGroup,
): AwsThingGroup[] => {
  const newDefaultThingGroups: AwsThingGroup[] = !thingGroups
    ? [value]
    : thingGroups?.some((dtg) => dtg.arn === value.arn)
    ? thingGroups.filter((dtg) => dtg.arn !== value.arn)
    : [...thingGroups, value];
  return newDefaultThingGroups;
};

/**
 * Returns object's keys with proper type safety
 * @param obj object
 */
export const objectKeys = <Obj extends object>(obj: Obj): (keyof Obj)[] => {
  return Object.keys(obj) as (keyof Obj)[];
};

/**
 * Returns a span with a bold part to highlight if it exists.
 * @param text string
 * @param highlight string
 * @returns ReactElement
 */
export const highlightTextPart = (
  text: string,
  highlight: string,
): React.ReactElement => {
  if (text.toLocaleLowerCase().indexOf(highlight.toLocaleLowerCase()) !== -1) {
    // const parts = text.toLocaleLowerCase().split(highlight.toLocaleLowerCase());
    const ocurrencesIndexes: number[] = [];
    let index = 0;
    while (index < text.length && index !== -1) {
      index = text
        .toLocaleLowerCase()
        .indexOf(
          highlight.toLocaleLowerCase(),
          ocurrencesIndexes.length === 0 ? 0 : index + highlight.length,
        );
      if (index !== -1) {
        ocurrencesIndexes.push(index);
      }
    }
    return (
      <p className="fw-400 m-0">
        {ocurrencesIndexes.map((ocurrenceIndex, i) => (
          <span key={`${text}-part-${i}`}>
            {text.substring(
              i === 0 ? 0 : ocurrencesIndexes[i - 1] + highlight.length,
              ocurrenceIndex,
            )}
            <b>
              {text.substring(
                ocurrenceIndex,
                ocurrenceIndex + highlight.length,
              )}
            </b>
            {i === ocurrencesIndexes.length - 1 &&
              text.substring(ocurrenceIndex + highlight.length, text.length)}
          </span>
        ))}
      </p>
    );
  }

  return <span>{text}</span>;
};

export const areJsonKeysDeletedOrRenamed = (
  oldObject: Record<string, unknown> | undefined,
  newObject: Record<string, unknown> | undefined,
): boolean => {
  if (!oldObject) {
    return false;
  }
  if (oldObject && !newObject) {
    return true;
  }

  const oldKeys = Object.keys(oldObject);

  for (const key of oldKeys) {
    if (!newObject || !newObject.hasOwnProperty(key)) {
      return true;
    }
  }

  return false;
};

/**
 * Returns the link from a text part. If the text has `http` then the link would be from the ocurrence of that until the first white space or end of text.
 * @param text string
 * @returns string
 */
export const getLinkFromValue = (text: string): string => {
  // Check for the existence of a link by checking if the text hast `http`
  const firstIndex = text.indexOf('http');
  if (firstIndex !== -1) {
    // if so get the link, until first white space or end of text
    const lastIndex = text.indexOf(' ', firstIndex);
    return text.substring(
      firstIndex,
      lastIndex !== -1 ? lastIndex : text.length,
    );
  }
  return '';
};

/**
 * Receives a string that should have '_' as default seperator of words or another seperator, and changes it to Title Case
 * @param text string
 * @returns string
 */
export const titleCase = (text: string, seperator = '_'): string => {
  const parts = text.split(seperator);
  console.info(parts);
  return parts.map((part) => capitalize(part)).join(' ');
};

/**
 * Opens a new tab with a valid url
 * @param url string
 */
export const openExtraLink = (url: string): void => {
  window.open(url, '_blank', 'noreferrer');
};

export const getCreatingSuccess = (model: string): string =>
  `${model} created successfully.`;

export const getCreatingError = (model: string): string =>
  `Error creating ${model}.`;

export const getFetchError = (model: string): string =>
  `Error getting ${model}.`;

export const getAttachError = (model: string, to: string): string =>
  `Error attaching ${model} to ${to}.`;

export const getDetachError = (model: string, to: string): string =>
  `Error detaching ${model} from ${to}.`;

const NOT_FOUND = 'not found';

export const isNotFoundFallback = (cb: () => void, msg?: string): void => {
  if (msg === NOT_FOUND) {
    cb();
  }
};
